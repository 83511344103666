import React from "react"
import { Helmet } from "react-helmet"

export const SeoFunnelStep0 = () => (
    <Helmet>
        <script type="application/ld+json">
            {`[{
                "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
            {
                "@type": "Question",
            "name": "¿Cómo puedo afiliarme a Prima AFP?",
            "acceptedAnswer": {
                "@type": "Answer",
            "text": "Bajo concepto actualmente solo puedes cambiarte a Prima AFP, puedes hacerlo a través de su página web oficial, donde encontrarás el formulario de traspaso."
                }
                },
            {
                "@type": "Question",
            "name": "¿Qué documentos necesito para cambiarme a Prima AFP?",
            "acceptedAnswer": {
                "@type": "Answer",
            "text": "Para traspasarte a Prima AFP, necesitarás solamente tu número documento de identidad. Asegúrate de tener una camará en tu dispositivo con la cual validaremos tu identidad."
                }
                },
            {
                "@type": "Question",
            "name": "¿Cuál es la rentabilidad de Prima AFP?",
            "acceptedAnswer": {
                "@type": "Answer",
            "text": "La rentabilidad de Prima AFP varía según el fondo que elijas. Generalmente, el fondo 3 ofrece una rentabilidad competitiva a un riesgo mayor. Te recomendamos consultar nuestra página web para obtener información actualizada sobre la rentabilidad de cada fondo."
                }
                },
            {
                "@type": "Question",
            "name": "¿Cómo puedo cambiarme a Prima AFP desde otra AFP?",
            "acceptedAnswer": {
                "@type": "Answer",
            "text": "Para cambiarte a Prima AFP, debes realizar una solicitud de traspaso, que puedes gestionar a través de nuestro sitio web. Necesitarás completar los 3 pasos en donde llenaras el formulario, eligiras el tipo de fondo y validaremos tu identidad."
                }
                },
            {
                "@type": "Question",
            "name": "¿Qué hacer si tengo dudas sobre mi saldo en Prima AFP?",
            "acceptedAnswer": {
                "@type": "Answer",
            "text": "Si tienes consultas sobre tu saldo en Prima AFP, puedes acceder a tu estado de cuenta a través de nuestra plataforma en línea. También puedes comunicarte con nuestro servicio de atención al cliente para recibir asistencia personalizada."
                }
                }
            ]
            }]`}
        </script>
    </Helmet>
)