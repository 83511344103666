import React, { useState, useEffect, useRef } from "react";
import { MainContainer } from "../../components/MainContainer";
import { Container } from "@prima-afp/prima-ui-elements/dist/layouts";
import Stepper from "../../components/Stepper";
import { StepsContent } from "../../componentes-sin-asesor/StepsContent";
import { useGetDataApi } from "../../hooks/useGetDataApi";
import {
  useStateDigitalTransfersContext,
  useDispatchDigitalTransfersContext,
} from "../../context/DigitalTransfersContext";
import { SendButton as SendButtonIdentityCD } from "../../componentes-sin-asesor/StepContactData/SendButton";
import { HeaderStep } from "../../../App/pages/layout-sin-asesor/HeaderSteps";

import { SendButton as SendButtonFundType } from "../../componentes-sin-asesor/StepFundType/SendButton";
import { useLocation } from "react-router-dom";
import { useSubmitOnEnter } from "../../hooks/useSubmitOnEnter";
import { useHistory } from "react-router-dom";
import { WhatsApp } from "../../components/BtnWhatsapp/index";
import { updateUrl } from "../../constants/updateUrl";
import iIconGray from "../../assets/images-con-asesor/infosGray.svg";
import { ContainerStepsPage, InfoContainer } from "./styles";
import { AccordionQuestions } from "modules/landing/components";

export const StepsPage = () => {
  const { GetDataInitial, GetDataWithToken, GetWhatsappValue } =
    useGetDataApi();
  const { stepCurrent, legaltext, whatslink, startFlow, lastStep } =
    useStateDigitalTransfersContext();

  const search = useLocation().search;
  const token_query = new URLSearchParams(search).get("t");
  const document = new URLSearchParams(search).get("dn");
  const { buttonRef, handleKeyDown } = useSubmitOnEnter();
  const history = useHistory();
  const dispatch = useDispatchDigitalTransfersContext();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 445);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 445);
  };

  const stepList = [
    {
      id: 0,
      title: "Bríndanos tus datos de contacto",
      subtitle: "Siguiente paso: Información para tu nueva cuenta",
    },
    {
      id: 1,
      title: "Bríndanos tus datos de contacto",
      subtitle: "Siguiente paso: Información para tu nueva cuenta",
    },
    {
      id: 2,
      title: "Completa la información para crear tu cuenta",
      subtitle: "Siguiente paso: Valida tu identidad con una selfie",
    },
    {
      id: 3,
      title: "Valida tu identidad con una selfie",
      subtitle: "Último paso: Valida tus datos de contacto",
    },
    { id: 4, title: "Valida tus datos de contacto", subtitle: "" },
  ];

  useEffect(() => {
    // Agregamos el event listener cuando el componente se monta
    window.addEventListener("resize", handleResize);

    // Limpiamos el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (whatslink == "") {
      GetWhatsappValue();
    }
    if (token_query != null) {
      GetDataWithToken(document, token_query);
    } else if (stepCurrent == 1) {
      GetDataInitial();
    }
  }, []);

  useEffect(() => {
    if (stepCurrent == 0) {
      dispatch({ type: "FIELD", payload: { field: "stepCurrent", value: 1 } });
    }
  }, [stepCurrent]);

  useEffect(() => {
    const currentUrl = window.location.href;
    if (stepCurrent == 0 && !window.location.href.includes("pasos0")) {
      dispatch({ type: "FIELD", payload: { field: "stepCurrent", value: 1 } });
      updateUrl(history, currentUrl, "/pasos0");
    }
  }, []);

  const accordionData = [
    {
      title: "Preguntas Frecuentes",
      content: (
        <ul className="custom-list-num">
          <li className="subTitle">1. ¿Cómo puedo afiliarme a Prima AFP?</li>
          Bajo concepto actualmente solo puedes cambiarte a Prima AFP, puedes
          hacerlo a través de su página web oficial, donde encontrarás el
          formulario de traspaso.
          <li className="subTitle">
            2. ¿Qué documentos necesito para cambiarme a Prima AFP?
          </li>
          Para traspasarte a Prima AFP, necesitarás solamente tu número
          documento de identidad. Asegúrate de tener una camará en tu
          dispositivo con la cual validaremos tu identidad.
          <li className="subTitle">
            3. ¿Cuál es la rentabilidad de Prima AFP?
          </li>
          La rentabilidad de Prima AFP varía según el fondo que elijas.
          Generalmente, el fondo 3 ofrece una rentabilidad competitiva a un
          riesgo mayor. Te recomendamos consultar nuestra página web para
          obtener información actualizada sobre la rentabilidad de cada fondo.
          <li className="subTitle">
            4. ¿Cómo puedo cambiarme a Prima AFP desde otra AFP?
          </li>
          Para cambiarte a Prima AFP, debes realizar una solicitud de traspaso,
          que puedes gestionar a través de nuestro sitio web. Necesitarás
          completar los 3 pasos en donde llenaras el formulario, eligiras el
          tipo de fondo y validaremos tu identidad.
          <li className="subTitle">5. ¿Qué hacer si tengo dudas sobre mi saldo en Prima AFP?</li>
          Si tienes consultas sobre tu saldo en Prima AFP, puedes acceder a tu
          estado de cuenta a través de nuestra plataforma en línea. También
          puedes comunicarte con nuestro servicio de atención al cliente para
          recibir asistencia personalizada.
        </ul>
      ),
    },
  ];

  return (
    <div
      id="wrapperStepsId"
      onKeyDown={handleKeyDown}
      style={{ width: "100%" }}
    >
      {!isMobile && (
        <Container id="idContainerHeader">
          <HeaderStep />
        </Container>
      )}

      <MainContainer id="idMainContainer">
        <Stepper stepList={stepList} currentStep={stepCurrent} />
        <StepsContent buttonRef={buttonRef} />
      </MainContainer>
      <ContainerStepsPage>
        {stepCurrent === 1 && <SendButtonIdentityCD buttonRef={buttonRef} />}
        {stepCurrent === 1 && (
          <>
            <InfoContainer>
              <img src={iIconGray} alt="" aria-hidden="true" />
              <p>
                Para traspasarte entre AFPs, no debes pertenecer a la ONP y
                debes tener más de 6 meses afiliado a una AFP. Estos son los
                requisitos mínimos por ser la primera vez que te afilias al
                Sistema Privado de Pensiones (SPP).
              </p>
            </InfoContainer>
            <div className="box-accordion">
              <AccordionQuestions items={accordionData} />
            </div>
          </>
        )}
        {stepCurrent === 2 && <SendButtonFundType buttonRef={buttonRef} />}
      </ContainerStepsPage>

      {isMobile && (
        <Container id="idContainerHeader MBtn">
          <HeaderStep isMobile={isMobile} />
        </Container>
      )}

      <WhatsApp />
    </div>
  );
};
