import React from "react";
import { Slide, SliderContainer, GradientOverlay } from "./style";

import { useHistory } from 'react-router-dom';

const SliderItemComponent = ({
  top,
  title,
  styleTitle,
  title2,
  subtitle,
  styleSubTitle,
  backgroundImages,
  background,
  buttons,
  contentRight,
}) => {
  const history = useHistory();
  return (
    <SliderContainer backgroundImage={backgroundImages} background={background}>
      <Slide>
        <div className={`conteinerLeft ${top}`}>
          <h2 className="title" style={styleTitle}>
            {title} {title2 && <p className="title2">{title2}</p>}
          </h2>
          {subtitle && (
            <p className='subTitle' style={styleSubTitle}>{subtitle}</p>
          )}
          <div className="buttonContainer">
            {buttons &&
              buttons.map((button, index) => (
                <button
                key={index}
                className={`${button.variant? 'btnSlideSecundary' : 'btnSlide'}`}
              onClick={() => button.action(history)}
            >
              {button.text}
            </button>
              ))}
          </div>
        </div>
        <div className="content-right">
          {contentRight && typeof contentRight === "function"
            ? contentRight()
            : contentRight}
        </div>
      </Slide>
      <GradientOverlay />
    </SliderContainer>
  );
};

export default SliderItemComponent;
