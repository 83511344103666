import React, { useEffect, useState } from "react";
import { MainContainer } from "../../components/MainContainer";
import { Text } from "../../../shared/components/Text";
import LikeIcon from "../../assets/images/like.svg";
import linkExtern from "../../assets/images/linkExtern.svg";
import {
  useStateDigitalTransfersContext,
  useDispatchDigitalTransfersContext,
} from "../../context/DigitalTransfersContext";
import { HeaderStep } from "../../../App/pages/layout-sin-asesor/HeaderSteps";
import { Container } from "@prima-afp/prima-ui-elements/dist/layouts";
import { ContainerFinishPage } from "../Style";
import { useHistory } from "react-router-dom";
import { sendToData } from "../../constants/sendToDataLayer";
import { updateUrl } from "../../constants/updateUrl";
import { FrameAddress } from "../../../digital-transfers/components/AddressFrame/FrameAddress";

import { useGetDataApi } from "../../hooks/useGetDataApi";
import { useCatchFetch } from "../../hooks/useCatchFetch";

export const FinishPage = () => {
  const { userWeb, urlFlow } = useStateDigitalTransfersContext();
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 445);
  const dispatch = useDispatchDigitalTransfersContext();
  const { GetDataInitial } = useGetDataApi();
  const { SetLoading } = useCatchFetch();
  const arrayFund = [{item_id: "F_1", item_name: "Fondo 1", fundType: 1},{item_id: "F_2", item_name: "Fondo 2", fundType: 2},{item_id: "F_3", item_name: "Fondo 3", fundType: 3},{item_id: "F_0", item_name: "Fondo 0", fundType: 0}];

  // TODO: descomentar al terminar
  useEffect(() => {
    const handlePopState = () => {
      sessionStorage.clear();
      localStorage.clear();
      dispatch({ type: "INITIAL_STATE" });
      dispatch({ type: "FIELD", payload: { field: "stepCurrent", value: 0 } });
      updateUrl(history, window.location.href, "/pasos0");
    };
    window.addEventListener("popstate", handlePopState);

    // Limpieza del evento cuando el componente se desmonta
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  // useEffect(() => {
  //   // Crear un nuevo elemento script
  //   const script = document.createElement("script");
  //   script.type = "text/javascript";
  //   script.src = `${process.env.REACT_APP_DYNATRACE_URL}`;
  //   script.crossOrigin = "anonymous";
    
  //   // Agregar el script al head del documento en la posición más alta posible
  //   document.head.insertBefore(script, document.head.firstChild);

  //   // Limpiar el script cuando el componente se desmonte
  //   return () => {
  //     document.head.removeChild(script);
  //   };
  // }, []);
  
  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.clear();
      localStorage.clear();
      dispatch({ type: "INITIAL_STATE" });
      dispatch({ type: "FIELD", payload: { field: "stepCurrent", value: 0 } });
      updateUrl(history, window.location.href, "/pasos0");
    };
  
    window.addEventListener("beforeunload", handleBeforeUnload);
  
    // Limpiar el evento al desmontar el componente
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  

  const handleResize = () => {
    setIsMobile(window.innerWidth < 445);
  };
  useEffect(() => {
    GetDataInitial();
  }, []);

  useEffect(() => {
    // Configurar un timer para redirigir después de 3 minutos (180000 ms)
    const timer = setTimeout(() => {
      sessionStorage.clear();
      localStorage.clear();
      dispatch({ type: "INITIAL_STATE" });
      dispatch({
        type: "FIELD",
        payload: { field: "showDivBenefits", value: false },
      });
      dispatch({ type: "FIELD", payload: { field: "stepCurrent", value: 0 } });
      updateUrl(history, window.location.href, "/pasos0");
    }, 180000);

    // Limpiar el timer si el usuario abandona la página antes de los 3 minutos
    return () => clearTimeout(timer);
  }, [history]);

  useEffect(() => {
    // Agregamos el event listener cuando el componente se monta
    window.addEventListener("resize", handleResize);

    // Limpiamos el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === "POP") {
        // Redirige a la página actual
        window.location.reload(true);
        return false;
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [history]);

  useEffect(() => {
    const nextLink = document.querySelector(
      'link[rel="next"][href="https://traspaso.prima.com.pe/finalizacion-solicitud"]'
    );
    if (nextLink) {
      document.head.removeChild(nextLink);
    }
  });

  useEffect(() => {
    const currentUrl = window.location.href;
    if(sessionStorage.getItem('goToStep4') === 'true'){
      createDTsendToData();
    }
    else if (
      userWeb.documentType != "00" ||
      userWeb.documentNumber == null ||
      userWeb.documentNumber == ""
    ) {
      updateUrl(history, currentUrl, "/pasos0");
    }
  }, []);

  const createDTsendToData= () => {
    SetLoading(true);
    const currentUrl = window.location.href;
    const timestamp = Date.now();
    const fund = arrayFund.find(item => item.fundType === userWeb.fundType);
    var payload = {
        section: "paso 3", option: "finalizar solicitud", 
        count_4: 1, 
        dni: userWeb.documentNumber,
        telefono: userWeb.phoneNumber,
        email: userWeb.email,
        edad: userWeb.age,
        transaction_id : "T_" + timestamp,
        items: [                        
            { item_id: fund.item_id, item_name: fund.item_name}                       
        ]
    };                 
    dispatch({ type: "FIELD", payload: { field: "stepCurrent", value: 5 } });
    sendToData(currentUrl, "step", "paso_4", payload, urlFlow);  
    sessionStorage.clear();
    localStorage.clear();              
  };

  const sendToGTM = (eventNum) => {
    switch (eventNum) {
      case 1:
        var currentUrl = window.location.href;
        var payload = {
          section: "paso 5",
          option: "volver al inicio",
        };
        sendToData(currentUrl, "button_click", "button_click", payload);
        break;
    }
  };

  return (
    <>
      {!isMobile && (
        <Container id="idContainerHeader">
          <HeaderStep />
        </Container>
      )}
      <MainContainer>
        <ContainerFinishPage id="idContainerFinishPage">
          <img className="iconFinish" src={LikeIcon} alt="finish icon" />

          {userWeb.firstName && (
            <Text id="text1Finish">
              ¡{userWeb.firstName}, tu solicitud se registró con éxito!
            </Text>
          )}
          {!userWeb.firstName && (
            <Text id="text1Finish">¡Tu solicitud se registró con éxito!</Text>
          )}

          {!userWeb.firstName ||
            ((userWeb.address != null || userWeb.address != "") && (
              <div className="addressDiv">
                <Text className="titleDescription">
                  Tu solicitud se registró con la siguiente dirección:
                </Text>
                <span className="addressDescription">{userWeb.address}</span>
              </div>
            ))}

          <div className="wrapperFinishButton">
            <a
              href="https://www.prima.com.pe/public-zone/"
              onClick={() => sendToGTM(1)}
              rel="noreferrer"
            >
              <button className="buttonFinish butFinish">
                <span className="spanTextBtnFinish">
                  Ir a la web de Prima AFP
                </span>
                <img src={linkExtern} />
              </button>
            </a>
          </div>
          {!userWeb.firstName ||
            ((userWeb.address != null || userWeb.address != "") && (
              <FrameAddress />
            ))}
        </ContainerFinishPage>
      </MainContainer>
    </>
  );
};
