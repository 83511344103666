/* eslint-disable react/prop-types */

import { string } from "prop-types";
import { sanitizar, sendToData } from "./sendToDataLayer";

const regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const OnlyLettersWithApostrofeWithNumbers = (word = "") => {
    if (!word || word === "") return false;
    return !/^[)(-_,.aábcdeéfghiíjklmnñoópqrstuúüvwxyzAÁBCDEÉFGHIÍJKLMNÑOÓPQRSTUÚÜVWXYZ1234567890' ]+$/.test(word);
};
export const ValidDocumentIdentity = (documentType, documentNumber) => {

    if (!documentType) {
        return "Seleccione un tipo de documento";
    }

    if (!documentNumber) {
        return "Ingrese su número de documento";
    }

    switch (documentType) {
        case "00":
            if (String(documentNumber).length !== 8) {
                return "Ingrese un número de documento válido";
            }
            break;
    }

    if (ValidNumberDocument(documentType, documentNumber)) return "Ingrese un número de documento válido";

    return null;
};

export const ValidNotNullContactData = (isAddressDNI, confirmationTC, userWeb) => {
    const fields = [
        'email',
        'emailConfirmation',
        'phoneNumber',
    ];
    const fields2 = [
        'departmentCode',
        'provinceCode',
        'districtCode',
    ];

    for (const field of fields) {
        if (!userWeb[field]) return true;
    }

    if (!isAddressDNI) {
        for (const field of fields2) {
            if (!userWeb[field]) return true;
        }
    }

    if (!confirmationTC) {
        if (!userWeb.tcConfirmation) { return true; }
    }

    return false;
};

export const ValidStepFundType = (userWeb, heldPosition, asesor = true) => {

    const {
        codeFundType,
        workerType,
        pepPositionHeld,
        pepInstitutionName,
    } = userWeb;

    if (!workerType) {
        return "Ingrese el tipo de empleado";
    }

    if (codeFundType == null) {
        return "Seleccione un tipo de fondo";
    }

    if (heldPosition) {
        if (!pepPositionHeld || !pepInstitutionName) {
            return "Complete todas las preguntas";
        } else {
            if (OnlyLettersWithApostrofeWithNumbers(pepPositionHeld) || OnlyLettersWithApostrofeWithNumbers(pepInstitutionName)) {
                var messageResponse = "Caracteres inválidos";
                var subSeccion = "";
                if (OnlyLettersWithApostrofeWithNumbers(pepPositionHeld) && !asesor) {
                    subSeccion = 'cargo desempeñado';
                    var payload = { section: 'paso 2', sub_section: subSeccion, option: sanitizar(messageResponse) };
                    sendToData(window.location.href, "step", "error", payload, window.location.href);
                }

                if (OnlyLettersWithApostrofeWithNumbers(pepInstitutionName) && !asesor) {
                    subSeccion = 'nombre de la institucion';
                    var payload = { section: 'paso 2', sub_section: subSeccion, option: sanitizar(messageResponse) };
                    sendToData(window.location.href, "step", "error", payload, window.location.href);
                }
                return messageResponse;
            }
            if (pepPositionHeld.length < 3 || pepInstitutionName.length < 3) {
                var messageResponse = "debe de ingresar un mínimo de 3 caracteres";
                var subSeccion = "";
                if (pepPositionHeld.length < 3 && !asesor) {
                    subSeccion = 'cargo desempeñado';
                    var payload = { section: 'paso 2', sub_section: subSeccion, option: sanitizar(messageResponse) };
                    sendToData(window.location.href, "step", "error", payload, window.location.href);
                }

                if (pepInstitutionName.length < 3 && !asesor) {
                    subSeccion = 'nombre de la institucion';
                    var payload = { section: 'paso 2', sub_section: subSeccion, option: sanitizar(messageResponse) };
                    sendToData(window.location.href, "step", "error", payload, window.location.href);
                }
                return messageResponse
            }
        }
    }


    return null;
};

export const ValidNumberDocument = (documentType, documentNumber) => {
    documentNumber = String(documentNumber);

    switch (documentType) {

        case "00":
            return !/^\d{8}$/.test(documentNumber);
        case "01":
            if (documentNumber.length < 6) {
                return !/^\d{5}$/.test(documentNumber);
            } else {
                return !/^((N|\d)(-|\d)\d{7})$/.test(documentNumber);
            }
        case "04":
            return !/^[a-zA-Z0-9]{5,12}$/.test(documentNumber);
        case "06":
            return !/^\d{1,9}$/.test(documentNumber);
    }

    return false;
};

export const ValidZone1 = (documentType, documentNumber, userWeb_val) => {

    const errorArray = [];

    if (!documentType || !documentNumber || String(documentNumber).length !== 8) {
        errorArray.push("errorDocNumberInput");
    }

    const {
        email,
        emailConfirmation,
        phoneNumber,
    } = userWeb_val;


    if (!email || !regexEmail.test(email)) {
        errorArray.push("errorEmailInput");
    }

    if (!phoneNumber || String(phoneNumber).charAt(0) !== "9" || String(phoneNumber).length !== 9) {
        errorArray.push("errorPhoneInput");
    }

    return errorArray;
};

export const ValidZone2 = (isAddressDNI, confirmationTC, userWeb_val, validSecontReniecActive) => {
    const errorArray = [];

    if (!confirmationTC) {
        errorArray.push("errorConfirmationTC");
    }

    const {
        departmentCode,
        provinceCode,
        districtCode,
        descriptionVia,
        descriptionHousing,
    } = userWeb_val;

    if (!isAddressDNI && validSecontReniecActive) {
        if (!descriptionHousing || descriptionHousing == '') {
            errorArray.push("descriptionHousingInput");
        }

        if (!descriptionVia || descriptionVia == '') {
            errorArray.push("descriptionViaInput");
        }

        if (!districtCode) {
            errorArray.push("districtCodeInput");
        }

        if (!provinceCode) {
            errorArray.push("provinceCodeInput");
        }

        if (!departmentCode) {
            errorArray.push("departmentCodeInput");
        }
    }

    return errorArray;
};