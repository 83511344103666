import { sendToDataLanding } from "../../digital-transfers/constants/sendToDataLayer";

export const redirectURL = (history, type, objectDataLayer = {}) => {
  if (objectDataLayer.event) sendToDataLanding(objectDataLayer);
  let url;
  if (type === "traspaso") {
    url = `/pasos0`;
  } else if (type === "query") {
    url = `/traspasos-digitales/consulta`;
  } else if (type === "traspasoAsesor") {
    url = `/asesor/pasos0`;
  } else if (type === "cuenta") {
    url = `https://miespacio.prima.com.pe`;
  }
  if (url) {
    if (type === "cuenta") {
      window.open(url, "_self", "noopener noreferrer");
    } else {
      history.push(url);
    }
  }
};