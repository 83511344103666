import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import PublicRouter from './PublicRouter';
import DigitalTransfersProvider from '../../src/modules/digital-transfers/context/DigitalTransfersContext';

const ApplicationRouter = ({ publicModules = [] }) => {

  return (
    <DigitalTransfersProvider>
      <BrowserRouter basename="/traspasos">
        <PublicRouter publicModules={ publicModules } />
      </BrowserRouter>
    </DigitalTransfersProvider>

  );
};

export default ApplicationRouter;