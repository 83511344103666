import React from "react"
import silde1 from "../assets/images/slider1.svg"
import silde2 from "../assets/images/slider2.svg"
import silde3 from "../assets/images/slider3.svg"
import bandera from "../assets/images/bandera.svg"
import calendario from "../assets/images/calendario.svg"
import avatar1 from "../assets/images/avatar1.svg"
import avatar2 from "../assets/images/avatar2.svg"
import { Link } from "react-router-dom"

/** data de componente header */
export const OptionsMenu = [
  {
    code: "cambiate hoy",
    description: "Cámbiate hoy",
    section: "page3",
  },
  {
    code: "¿cuanto toma?",
    description: "¿Cuánto toma?",
    section: "page4",
  },
  {
    code: "preguntas frecuentes",
    description: "Preguntas frecuentes",
    section: "page5",
  },
]

/** data de componente timeLine */
export const stepDatos = [
  { content: "Ingresa tus datos de contacto" },
  { content: "Elige el tipo de fondo" },
  { content: "Confirma tu identidad con una selfie y ¡Listo!" },
]

/** data de componente accordion */
export const accordionData = [
  {
    title: "¿Quienes pueden hacer el trámite de traspaso?",
    content: (
      <ul className="custom-list-num">
        <li className="subTitle">
          1. Todos los que estén afiliados en cualquiera de las otras AFP.
        </li>
        <li className="subTitle">
          2. Los que no tienen ninguno de estos trámites en proceso:
        </li>
        <ul className="custom-list-vi">
          <li style={{ marginLeft: "17px" }}>Traspaso anterior en proceso.</li>
          <li style={{ marginLeft: "17px" }}>Desafiliación o nulidad.</li>
          <li style={{ marginLeft: "17px" }}>Solicitud de evaluación y calificación de invalidez.</li>
        </ul>
        Si ingresaste al SPP a partir de 01 de junio de 2013, adicionalmente
        debes cumplir uno de estos requisitos:
        <ul className="custom-list-vi">
          <li style={{ marginLeft: "17px" }}>Debes tener como mínimo dos años de afiliado en tu AFP.</li>
          <li style={{ marginLeft: "17px" }}>
            Debes tener más de 180 días* de afiliado y verificar que tu
            rentabilidad obtenida es menor al promedio del sistema** (para esto
            tu AFP debe emitir una constancia)
          </li>
        </ul>
        (*) Deben ser 180 días calendario.(**) Es el dato de tu rentabilidad
        neta de comisiones calculada al momento en que solicitas tu traspaso y
        tiene una validez por 10 días calendario.
      </ul>
    ),
  },
  {
    title: "¿Cómo puedes realizar la solicitud de traspaso?",
    content: (
      <ul className="custom-list-num">
        <li className="subTitle">1. Realiza tu solicitud de traspaso:</li>
        <li style={{ marginLeft: "17px" }}>
          Elige uno de los dos medios para solicitar tu cambio a Prima AFP:
        </li>
        <li style={{ marginLeft: "17px" }}>100% digital:</li>
        <li style={{ marginLeft: "17px" }}>
          Completa en solo 4 pasos tu solicitud de traspaso desde tu PC, tablet
          o celular{" "}
          <Link to="/pasos0" className="linksTraspasos" id="btn-change-question">
            aquí.
          </Link>
        </li>
        <li style={{ marginLeft: "17px" }}>Correo electrónico:</li>
        <li style={{ marginLeft: "17px" }}>
          Escríbenos a traspaso@prima.com.pe y adjunta:
        </li>
        <li style={{ marginLeft: "17px" }}>
          Tu{" "}
          <a
            id="btn-pdf"
            href="https://www.prima.com.pe/public-zone/files/2022/pdf/Prima_Traspaso.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="linksTraspasos"
          >
            solicitud de traspaso
          </a>{" "}
          firmada y escaneada
        </li>
        <li style={{ marginLeft: "17px" }}>
          Una copia de tu documento de identidad vigente
        </li>
        <li style={{ marginLeft: "17px" }}>
          Recibirás un correo de confirmación y te llamaremos para validar los
          datos
        </li>
        <li className="subTitle">2. Recibe nuestra respuesta:</li>
        <li style={{ marginLeft: "17px" }}>
          En los siguientes 30 días calendario te informaremos por correo
          electrónico si tu solicitud ha sido aceptada.
        </li>
      </ul>
    ),
  },
  {
    title: "¿Cómo pasarme a Prima AFP?",
    content: (
      <ul className="custom-list-num">
        Si estás afiliado a una AFP, puedes traspasarte de forma virtual a
        PRIMA AFP en{" "}
        <a
          href="https://www.prima.com.pe/"
          target="_blank"
          rel="noopener noreferrer"
          className="linksTraspasos"
        >
          www.prima.com.pe
        </a>
        {" "}Consideraciones:
        <ul className="custom-list-vi">
          <li style={{ marginLeft: "17px" }}>
            Puedes realizar tu traspaso de AFP después de un mínimo de 2 años en
            tu AFP actual.
          </li>
          <li style={{ marginLeft: "17px" }}>
            Encuentra más información sobre la rentabilidad y comisiones en{" "}
            <a
              href="https://www.prima.com.pe/"
              target="_blank"
              rel="noopener noreferrer"
              className="linksTraspasos"
            >
              www.prima.com.pe
            </a>
          </li>
        </ul>
      </ul>
    ),
  },
  {
    title: "¿Qué pasa si me traslado de AFP?",
    content: (
      <ul className="custom-list-num">
        Al cambiarte de AFP, trasladas el total de tus aportes obligatorios a
        la AFP que elijas.
      </ul>
    ),
  },
  {
    title: "¿El traspaso de AFP tiene algún costo?",
    content: (
      <ul className="custom-list-num">
        No, es 100% gratis. No tiene ningún costo ni cobro de comisión el
        traspasarte de una AFP a otra.
      </ul>
    ),
  },
  {
    title: "¿Si me cambio de AFP pierdo dinero?",
    content: (
      <ul className="custom-list-num">
        No, los cambios de AFP no afectan tu fondo. Recuerda que puedes hacer
        tu cambio de AFP hacia Prima AFP{" "}
        <Link to="/pasos0" className="linksTraspasos" id="btn-change-question">
          aquí.
        </Link>
      </ul>
    ),
  },
  {
    title: "¿Qué es la afiliación a la AFP?",
    content: (
      <ul className="custom-list-num">
        Si eres trabajador dependiente (en planilla) o independiente puedes
        afiliarte a una AFP. Si tu AFP es distinta a Prima, puedes solicitar
        tu traspaso 100% digital en{" "}
        <Link to="/pasos0" className="linksTraspasos" id="btn-change-question">
          {`${process.env.REACT_APP_BASE_URL_TRASPASO}/pasos0`}
        </Link>.
      </ul>
    ),
  },
]

/** data de componente slider/tabs */
export const settingsTabs = {
  dots: false, // Oculta los puntos en escritorio
  infinite: false, // Desactiva el movimiento en escritorio
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024, // Desktop breakpoint
      settings: {
        dots: false, // Oculta los puntos en escritorio
        infinite: false, // Desactiva el movimiento en escritorio
        slidesToShow: 3, // Muestra 3 slides en escritorio
        slidesToScroll: 1,
        arrows: false,
      }
    },
    {
      breakpoint: 700, // Mobile breakpoint
      settings: {
        dots: true, // Muestra los puntos en móvil
        infinite: true, // Activa el movimiento en móvil
        slidesToShow: 1, // Muestra 1 slide en móvil
        slidesToScroll: 1,
        arrows: false,
      }
    }
  ]
};

/** data de componente slider/tabs */
export const itemsSliderTabs = [
  {
    id: 1,
    iconGlobo: bandera,
    textGlobo: "Hoy",
    title: "Ingresa tu solicitud",
    text: "Sigue los pasos y hazlo de manera virtual.",
    textLink: "Cámbiate hoy",
    link: "traspaso",
    image: silde1,
  },
  {
    id: 2,
    iconGlobo: calendario,
    textGlobo: "En un mes",
    title: "Tu solicitud será aceptada",
    text: "En este tiempo tu AFP de origen y la SBS revisarán tu solicitud.",
    textLink: "Hazle seguimiento",
    link: "query",
    image: silde2,
    classContainer: "classContainer"
  },
  {
    id: 3,
    iconGlobo: calendario,
    textGlobo: "En 2 meses",
    title: "¡Tu traspaso está listo!",
    text: "Tu AFP de origen hará el traspaso de tus fondos a Prima AFP.",
    textLink: "Ingresa a tu cuenta",
    link: "cuenta",
    image: silde3,
    classContainer: "classContainer"
  },
]

/** settings de componente slider/testimonial */
export const settingsTestimonial = {
  autoplay: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  draggable: true,
  vertical: true, // Modo vertical por defecto
  responsive: [
    {
      breakpoint: 541, // Mobile breakpoint
      settings: {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        vertical: false, // Modo horizontal en móvil
        centerMode: true, // Activa el modo centrado
        centerPadding: "27px", // Ajusta el espacio visible a los lados
      },
    },
  ],
}

/** data de componente slider/testimonial */
export const testimonialsItems = [
  {
    id: 1,
    avatar: avatar1,
    name: "Jorge Munive",
    text: "Se preocupan por la estabilidad y la tranquilidad de sus afiliados",
  },
  {
    id: 2,
    avatar: avatar2,
    name: "Anónimo",
    text: "El proceso fue muy rápido, la asesora fue muy efectiva y amable",
  },
]

