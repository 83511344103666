import styled from "styled-components";

export const Icon = styled.img`
    @media only screen and (max-width : 451px) {    
      .alert{
        width: 80px;
        height: 80px;
      }
      width: 56px;
      height: 56px;
    }

    @media only screen and (min-width : 451px) and (max-width: 1000px) {        
      width: 56px;
      height: 56px;
    }
`;

export const HeaderModal = styled.div`
  min-width: 100%;
  .close-modal {
    position: absolute;
    top: 15px;
    right: 20px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 20px;
    color: #999;
  }
`;
export const ModalErrorContent = styled.div`
  #idHaveRequest{
    width: 170px;
    height: 170px;
  }   
  .title{
      position: relative;
      color: var(--Text-Gray-Default, #4e5258);
      text-align: center;
      font-variant-numeric: lining-nums proportional-nums;
      align-self: stretch;
      margin-top: 19px;
      font: 600 24px/108% Lexend, -apple-system, Roboto, Helvetica, sans-serif;
  }
  .content{
      position: relative;
      color: var(--Text-Gray-Default, #4e5258);
      text-align: center;
      margin-top: 25px;
      width: 252px;
      font: 400 16px/20px Calibri, -apple-system, Roboto, Helvetica, sans-serif;
  }
  .widthContent{
    width: 100% !important;
  }
  .centerContent{
    margin: 25px auto !important;
  }
  .newUiBtn{
      justify-content: center;
      border-radius: var(--Radius-Radius-16, 16px);
      white-space: nowrap;
      text-align: center;
      letter-spacing: 0.02px;
      padding: 16px 20px;
      font: 400 18px / 133% Lexend, -apple-system, Roboto, Helvetica, sans-serif;
  }

  .btnAlreadyClient{
      padding: 16px 20px;
      border-radius: 16px;
      border: 1.5px solid var(--Border-Primary-Default, rgba(255, 79, 0, 1));
      font-family: Lexend;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.0012em;
      text-align: center;
      color: var(--Text-Primary-Darker, rgba(238, 68, 11, 1));
      background-color: #fff;
      margin-top: 12px;
  }

  .disabledBtn{
        border-radius: var(--Radius-Radius-16, 16px);
        background-color: var(--Background-Disabled-Subtle, #e7e7e8);
        color: var(--Text-Disable-Disabled, #84878b);
  }
  .enabledBtn{
      background-color: var(--orangeColorMain);
      color: #FFFFFF;
  }
    

  @media only screen and (max-width : 451px) {       
    .newUiBtn{
      width: 252px;
    }  
  }

  @media only screen and (min-width : 451px) {        
    .newUiBtn{
      width: 252px;
    } 
  }
`;