import { Button } from "@prima-afp/prima-ui-elements/dist/core";
import Bowser from "bowser";
import platform from "platform";
import { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router-dom";
import { CONTENT_CLIENT_PRIMA } from "../../../shared/constants";
import found1 from "../../assets/images/found1.svg";
import found1Green from "../../assets/images/found1Green.svg";
import found2 from "../../assets/images/found2.svg";
import found2Green from "../../assets/images/found2Green.svg";
import found3 from "../../assets/images/found3.svg";
import found3Green from "../../assets/images/found3Green.svg";
import { ParseResponse } from "../../constants/parse";
import { sendToData } from "../../constants/sendToDataLayer";
import { updateUrl } from "../../constants/updateUrl";
import { ValidZone1 } from "../../constants/validate";
import {
  useDispatchDigitalTransfersContext,
  useStateDigitalTransfersContext,
} from "../../context/DigitalTransfersContext";
import { useCatchFetch } from "../../hooks/useCatchFetch";
import { SendDocumentIdentity, checkTocAttempts } from "../../services";
import { FrameAuthorization } from "./FrameAuthorization";
import { ButtonsContainer } from "./styles";
import { ButtonFloating } from "modules/digital-transfers/components/ButtonFloating/ButtonFloating";

export const SendButton = ({ buttonRef }) => {
  const state = useStateDigitalTransfersContext();
  const { SendFetch, TransparentSendFetch, SetLoading } = useCatchFetch();
  const history = useHistory();
  const { isAddressDNI, haveAsesor, userWeb, validReniecActive, withoutHelp,
    showPersonalData, } =
    state;

  const {
    documentType,
    documentNumber,
    email,
    phoneNumber,
    adpConfirmation,
    useToc,
  } = userWeb;

  const dispatch = useDispatchDigitalTransfersContext();
  const { urlFlow } = useStateDigitalTransfersContext();

  const reCaptchaRef = useRef();
  const [indPath, setindPath] = useState(1);
  const [formDisabled, setFormDisabled] = useState(true);

  useEffect(() => {
    if (
      userWeb.documentType.length > 0 &&
      userWeb.documentNumber.length == 8 &&
      phoneNumber.length == 9 &&
      email.length > 0
    ) {
      setFormDisabled(false);
    } else {
      setFormDisabled(true);
    }
  }, [userWeb, phoneNumber, email]);

  const Send = () => {
    sessionStorage.setItem("timestampFirstQuery", Date.now());
    sessionStorage.setItem("originalUtmParams", window.location.href);
    onSubmitDocument();
  };

  const getDataDevice = () => {
    // Obtenemos información del dispositivo y del navegador
    const deviceType = getDeviceType();
    const os = getOperatingSystem();
    const browserInfo = getBrowserInfo();
    const deviceInfo =
      deviceType +
      "|" +
      os +
      "|" +
      browserInfo.name +
      "|" +
      browserInfo.version;
    return deviceInfo;
  };

  const getDeviceType = () => {
    // Detectar el tipo de dispositivo basado en el ancho de la ventana
    const width = window.innerWidth;
    if (width <= 480) {
      return "Móvil";
    } else if (width <= 1024) {
      return "Tablet";
    } else {
      return "PC";
    }
  };

  const getOperatingSystem = () => {
    // Obtener información sobre el sistema operativo
    const os = platform.os;
    return `${os.family} ${os.version}`;
  };

  const getBrowserInfo = () => {
    const browserInfo = Bowser.getParser(window.navigator.userAgent);
    return {
      name: browserInfo.getBrowserName(),
      version: browserInfo.getBrowserVersion(),
    };
  };

  const validateAttemps = async () => {
    SetLoading(true);
    try {
      SetLoading(true);
      let payload = {
        section: "paso 0",
        documentNumber: userWeb.documentNumber,
        message: "dni antes de validateAttemps",
      };
      sendToData(window.location.href, "button_click", "validate_attemps", payload);
      if (userWeb.useToc == true &&
        userWeb.documentNumber != null &&
        userWeb.documentNumber !== "" &&
        /^[0-9]{8}$/.test(userWeb.documentNumber)) {
        const tocRes = await TransparentSendFetch(checkTocAttempts(userWeb.documentNumber));
        //setear la variable del flujo para usar toc
        dispatch({ type: "FIELD_SECOND", payload: { field1: "userWeb", field2: "biometricType", value: 'TOC', }, });
        if (tocRes.count <= 0) {
          //debe mostrar el modal de intentos superados
          dispatch({
            type: "NOT_VALID_DOCUMENT",
            payload: {
              idModal: "btn-attempts-biometric",
              content: "Superaste la cantidad de intentos. Por favor, intenta en unos minutos.",
              changeLoading: false,
              className: "centerContent",
            },
          });
          return 'TOC_ACTIVE_NO_ATTEMPS';
        }
        return 'TOC_ACTIVE';
      } else {
        return 'FACEPHI_ACTIVE';
      }

    } catch (e) {
      //setear la variable del flujo para usar FACEPHI
      dispatch({ type: "FIELD_SECOND", payload: { field1: "userWeb", field2: "biometricType", value: 'FACEPHI', }, });
      console.log("error", e);
      return 'FACEPHI_ACTIVE';
    }
  };

  const onSubmitDocument = async () => {
    SetLoading(true);
    dispatch({
      type: "FIELD_SECOND",
      payload: {
        field1: "userWeb",
        field2: "errorDocNumberInput",
        value: false,
      },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: { field1: "userWeb", field2: "errorPhoneInput", value: false },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: { field1: "userWeb", field2: "errorEmailInput", value: false },
    });

    console.log('onSubmitDocument 1');
    reCaptchaRef.current.reset();
    const recaptchaToken = await reCaptchaRef.current.executeAsync();
    console.log('onSubmitDocument 2', recaptchaToken);
    const validation = ValidZone1(documentType, documentNumber, userWeb);

    if (validation.length == 0) {
      const body = {
        documentType,
        documentNumber,
        recaptchaToken,
        email,
        phoneNumber,
        validReniec: validReniecActive,
        byAssessor: false,
        device: getDataDevice(),
        indPath: indPath,
        tcConfirmation: true,
        adpConfirmation,
      };
      const rpta = await validateAttemps();
      if (rpta != 'TOC_ACTIVE_NO_ATTEMPS') {
        SetLoading(true);
        SendFetch(SendDocumentIdentity(body))
          .then((res) => {
            const currentUrl = window.location.href;
            const haveAVal = haveAsesor;
            const withoutHelpVal = withoutHelp;
            //limpiar todos los datos
            dispatch({ type: "INITIAL_STATE" });
            dispatch({
              type: "FIELD",
              payload: { field: "confirmationTC", value: true },
            });
            dispatch({
              type: "FIELD_SECOND",
              payload: {
                field1: "userWeb",
                field2: "tcConfirmation",
                value: true,
              },
            });
            dispatch({
              type: "FIELD",
              payload: { field: "urlFlow", value: currentUrl },
            });
            dispatch({
              type: "FIELD",
              payload: { field: "haveAsesor", value: haveAVal },
            });
            dispatch({ type: "FIELD", payload: { field: "lastStep", value: 1 } });
            dispatch({
              type: "FIELD",
              payload: { field: "withoutHelp", value: withoutHelpVal },
            });
            //guardar solo los sgtes datos: documentType, documentNumber, email, phoneNumber
            dispatch({
              type: "FIELD_SECOND",
              payload: {
                field1: "userWeb",
                field2: "documentType",
                value: body.documentType,
              },
            });
            dispatch({
              type: "FIELD_SECOND",
              payload: {
                field1: "userWeb",
                field2: "documentNumber",
                value: body.documentNumber,
              },
            });
            dispatch({
              type: "FIELD_SECOND",
              payload: {
                field1: "userWeb",
                field2: "codeProspect",
                value: res.codeProspect,
              },
            });
            dispatch({
              type: "FIELD_SECOND",
              payload: { field1: "userWeb", field2: "email", value: body.email },
            });
            dispatch({
              type: "FIELD_SECOND",
              payload: {
                field1: "userWeb",
                field2: "phoneNumber",
                value: body.phoneNumber,
              },
            });
            if (res.code === -1) {
              if (res.message.includes("ErrorAttemptsBio:")) {
                const auxString = res.message;
                const contentModal = auxString.replace("ErrorAttemptsBio:", "");
                dispatch({
                  type: "NOT_VALID_DOCUMENT",
                  payload: {
                    idModal: "btn-attempts-biometric",
                    content: contentModal,
                    changeLoading: false,
                    className: "centerContent",
                  },
                });
              } else {
                dispatch({
                  type: "NOT_VALID_DOCUMENT",
                  payload: {
                    idModal: "btn-error-documentIdentity",
                    content:
                      "Hubo un inconveniente con la consulta de tu documento de identidad, por favor vuelve a intentarlo.",
                    changeLoading: false,
                    className: "centerContent",
                  },
                });
                var payload = {
                  section: "paso 0",
                  option: `hubo un inconveniente en la consulta de tu documento  ${res.code}`,
                  dni: body.documentNumber,
                };
                sendToData(
                  window.location.href,
                  "step",
                  "error",
                  payload,
                  window.location.href
                );
                dispatch({
                  type: "FIELD",
                  payload: { field: "stepCurrent", value: 0 },
                });
              }
              SetLoading(false);
            } else if (res.code === 0 || res.code === 1 || res.code === 4) {
              dispatch({
                type: "FIELD",
                payload: { field: "validReniecActive", value: true },
              });
              dispatch({
                type: "FIELD",
                payload: { field: "isAddressDNI", value: true },
              });
              localStorage.setItem("DNI", body.documentNumber);
              if (res.code === 4) {
                //seteo de validSecontReniecActive
                dispatch({
                  type: "FIELD",
                  payload: { field: "validReniecActive", value: false },
                });
                dispatch({
                  type: "FIELD",
                  payload: { field: "isAddressDNI", value: true },
                });
                dispatch({
                  type: "FIELD",
                  payload: { field: "codeValidateDocument", value: 4 },
                });
                res.userWebTransfer = {};
                res.userWebTransfer.documentType = userWeb.documentType;
                res.userWebTransfer.documentNumber = userWeb.documentNumber;
                res.userWebTransfer.phoneNumber = userWeb.phoneNumber;
                res.userWebTransfer.email = userWeb.email;
                res.userWebTransfer.firstName = "";
              }
              res = ParseResponse(res);
              dispatch({
                type: "VALID_DOCUMENT",
                payload: {
                  userWebTransfer: res.userWebTransfer,
                  userWeb: res.userWeb,
                  validReniec: res.userWebTransfer.validReniec,
                  isAddressDNI: isAddressDNI,
                  validReniecActive: validReniecActive,
                  changeLoading: false,
                  enabledAbandonmentStep1: true,
                },
              });

              if (res.userWebTransfer.age > 59) {
                const list = [
                  {
                    id: 0,
                    type: 0,
                    name: "Fondo 0",
                    content:
                      "Seguro y estable, ideal si prefieres evitar riesgos si estás cerca de jubilarte.",
                    strongDescription: "",
                    img: found1,
                    imgSelect: found1Green,
                  },
                  {
                    id: 1,
                    type: 1,
                    name: "Fondo 1",
                    content:
                      "Crecimiento con seguridad, perfecto si buscas un balance sin mucho riesgo.",
                    strongDescription: "",
                    img: found2,
                    imgSelect: found2Green,
                  },
                  {
                    id: 2,
                    type: 2,
                    name: "Fondo 2",
                    content:
                      "Crecimiento equilibrado, ideal si eres paciente y quieres más rendimiento.",
                    strongDescription: "",
                    img: found3,
                    imgSelect: found3Green,
                  },
                ];

                dispatch({
                  type: "FIELD",
                  payload: { field: "typesList", value: list },
                });

                dispatch({
                  type: "FIELD_SECOND",
                  payload: { field1: "userWeb", field2: "fundType", value: 0 },
                });
                dispatch({
                  type: "FIELD_SECOND",
                  payload: {
                    field1: "userWeb",
                    field2: "codeFundType",
                    value: 0,
                  },
                });
              }
              dispatch({
                type: "FIELD_SECOND",
                payload: {
                  field1: "userWeb",
                  field2: "age",
                  value: res.userWebTransfer.age,
                },
              });

              //enviar marcación atm
              var payload_step1 = {
                section: "paso 1_1",
                option: "siguiente",
                count_1_1: 1,
                dni: userWeb.documentNumber,
                telefono: userWeb.phoneNumber,
                email: userWeb.email,
                edad: res.userWebTransfer.age,
              };
              sendToData(
                window.location.href,
                "step",
                "paso_1_1",
                payload_step1,
                window.location.href
              );

              SetLoading(false);

              //mandar a paso 2
              dispatch({
                type: "FIELD",
                payload: { field: "stepCurrent", value: 2 },
              });
              updateUrl(history, window.location.href, "/pasos2");
            } else if (res.code === 2) {
              dispatch({
                type: "NOT_VALID_DOCUMENT",
                payload: {
                  idModal: "btn-prima-client",
                  type: "happy",
                  title: "Eres cliente de Prima AFP",
                  content: CONTENT_CLIENT_PRIMA,
                  textButton: "Entendido",
                  changeLoading: false,
                  className: "centerContent",
                },
              });
              var payloadClient = {
                section: "validacion_cliente",
                message: "ya es cliente",
                dni: userWeb.documentNumber,
                telefono: userWeb.phoneNumber,
                email: userWeb.email,
              };
              dispatch({
                type: "FIELD",
                payload: { field: "stepCurrent", value: 0 },
              });
              sendToData(
                window.location.href,
                "mensaje_click",
                "mensaje",
                payloadClient
              );
            } else if (res.code === 3) {
              dispatch({
                type: "NOT_VALID_DOCUMENT",
                payload: {
                  idModal: "btn-have-request",
                  type: "haveRequest",
                  title: "Ya tienes una solicitud",
                  content:
                    "<p>Tu traspaso hacia Prima AFP está en proceso, para mayor información llámanos al 615-7272 en Lima o al 0-801-18010 en provincias</p>",
                  textButton: "Entendido",
                  changeLoading: false,
                  className: "centerContent",
                },
              });
              dispatch({
                type: "FIELD",
                payload: { field: "stepCurrent", value: 0 },
              });
              var payloadRequest = {
                section: "validacion_cliente",
                message: "tiene solicitud",
                dni: userWeb.documentNumber,
                telefono: userWeb.phoneNumber,
                email: userWeb.email,
              };
              sendToData(
                window.location.href,
                "mensaje_click",
                "mensaje",
                payloadRequest
              );
            } else if (res.code === 5 || res.code === 6) {
              var message = res.message.includes("ONP")
                ? "usuario afiliado a ONP"
                : "Integra < 180 dias";
              var contentAlert = res.message.includes("ONP")
                ? `Observamos que no estás afiliado al Sistema Privado de Pensiones. Por consiguiente, lamentamos informarte que no podemos asistirte en el proceso de traslado a Prima AFP.`
                : `<p style="text-align: start;">Lamentamos informarte que no puedes solicitar el cambio de AFP o el traspaso excepcional en este momento. Para poder realizar esta solicitud, es necesario que cumplas con los siguientes requisitos:</p> <br />
                  <p style="text-align: start;">- Haber estado afiliado a AFP Integra por un mínimo de 180 días y un máximo de 730 días, contados desde la fecha de tu afiliación al Sistema Privado de Pensiones (SPP).</p>
                  <p style="text-align: start;"> - Que la rentabilidad neta de comisiones en AFP Integra sea menor a la de AFP Prima.</p>
                  <p style="text-align: start;">- Te recomendamos revisar tu situación actual y, si cumples con estas condiciones en el futuro, no dudes en contactarnos nuevamente.</p> `;

              dispatch({
                type: "NOT_VALID_DOCUMENT",
                payload: {
                  idModal: "btn-onp-affiliate",
                  type: "alert",
                  title: "Lo sentimos",
                  content: contentAlert,
                  textButton: "Entendido",
                  changeLoading: false,
                  className: "widthContent",
                },
              });
              dispatch({
                type: "FIELD",
                payload: { field: "stepCurrent", value: 0 },
              });

              var payloadATM = {
                section: "validacion_onp",
                message: message,
                option: "entendido",
                dni: userWeb.documentNumber,
                telefono: userWeb.phoneNumber,
                email: userWeb.email,
              };
              sendToData(
                window.location.href,
                "mensaje_click",
                "mensaje",
                payloadATM
              );
            } else {
              dispatch({
                type: "NOT_VALID_DOCUMENT",
                payload: {
                  idModal: "btn-error-documentIdentity",
                  content:
                    "Hubo un inconveniente con la consulta de tu documento de identidad, por favor vuelve a intentarlo.",
                  changeLoading: false,
                  className: "centerContent",
                },
              });
              dispatch({
                type: "FIELD",
                payload: { field: "stepCurrent", value: 0 },
              });
              var payload2 = {
                section: "paso 0",
                option: `hubo un inconveniente en la consulta de tu documento ${res.code}`,
                dni: body.documentNumber,
              };
              sendToData(
                window.location.href,
                "step",
                "error",
                payload2,
                urlFlow
              );
            }
          })
          .catch((e) => {
            console.log("error", e);
            dispatch({
              type: "NOT_VALID_DOCUMENT",
              payload: {
                idModal: "btn-error-documentIdentity",
                content:
                  "Hubo un inconveniente con la consulta de tu documento de identidad, por favor vuelve a intentarlo.",
                changeLoading: false,
                textButton: "Volver",
                className: "centerContent",
              },
            });
            dispatch({
              type: "FIELD",
              payload: { field: "stepCurrent", value: 0 },
            });
            var payload3 = {
              section: "paso 0",
              option: `hubo un inconveniente en la consulta de tu documento ${res.code}`,
              dni: body.documentNumber,
            };
            sendToData(window.location.href, "step", "error", payload3, urlFlow);
          });
      }

    } else {
      setErrorValidationIdentity(validation);
    }
  };

  const setErrorValidationIdentity = (validation) => {
    if (validation.includes("errorDocNumberInput")) {
      dispatch({
        type: "FIELD_SECOND",
        payload: {
          field1: "userWeb",
          field2: "errorDocNumberInput",
          value: true,
        },
      });
    }
    if (validation.includes("errorPhoneInput")) {
      dispatch({
        type: "FIELD_SECOND",
        payload: {
          field1: "userWeb",
          field2: "errorPhoneInput",
          value: true,
        },
      });
      var payload = {
        section: "paso 0",
        option: "el numero de telefono no es valido",
        dni: documentNumber,
      };
      sendToData(window.location.href, "step", "error", payload, urlFlow);
    }
    if (validation.includes("errorEmailInput")) {
      dispatch({
        type: "FIELD_SECOND",
        payload: {
          field1: "userWeb",
          field2: "errorEmailInput",
          value: true,
        },
      });
      var payload1 = {
        section: "paso 0",
        option: "e-mail invalido",
        dni: documentNumber,
      };
      sendToData(window.location.href, "step", "error", payload1, urlFlow);
    }
    SetLoading(false);
  };

  const isDisabled = () => {
    if (
      userWeb.documentType.length > 0 &&
      userWeb.documentNumber.length == 8 &&
      phoneNumber.length == 9 &&
      email.length > 0
    ) {
      return false;
    }
    return true;
  };

  return (
    <>
      <ReCAPTCHA
        ref={reCaptchaRef}
        sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
        size="invisible"
        style={{ visibility: 'hidden' }}
      />

      {(showPersonalData && validReniecActive &&
        <div>
          <FrameAuthorization />
        </div>
      ) ||
        (validReniecActive == false && (
          <div>
            <FrameAuthorization />
          </div>
        ))}

      <ButtonsContainer>
        <Button
          id="identity-btn-env-12"
          className={
            formDisabled
              ? "buttonStep1 newUiBtn disabledBtn"
              : "buttonStep1 newUiBtn enabledBtn"
          }
          onClick={Send}
          ref={buttonRef}
          disabled={isDisabled()}
        >
          Continuar
        </Button>
      </ButtonsContainer>
      {!isDisabled() && (
        <ButtonFloating
          buttonRef={buttonRef}
          action={Send}
          text="Continuar"
        />
      )}
    </>
  );
};