import styled, { css } from "styled-components";
import { Text } from "../../../App/pages/Text";
import { media } from "@prima-afp/prima-ui-elements/dist/utils/media/styledConfig";
import { Select } from "@prima-afp/prima-ui-elements/dist/core";
import flecha from "../../../App/assets/images/flecha2.svg"
import { mediaQuery } from "modules/utils/responsive";

export const ContainerInputs = styled.div`
    width: 50%;
    margin: 0 auto;

    ${media.sm`
        width: 100%;
    `};

    ${media.xs`
        width: 100%;
    `};

    .inputDoc{
        position: relative !important;
        top: 10px !important;
    }

    @media only screen and (max-width : 450px) { 
        .disclaimerStep1{
            display: flex;
            color: var(--Text-Gray-Default, #4e5258);
            max-width: 312px;
            font: 700 16px/20px Calibri, -apple-system, Roboto, Helvetica, sans-serif;
            margin-bottom: 8px;
        }
    }
    @media only screen and (min-width : 451px) { 
        .disclaimerStep1{
            display: none;
        }
    }

`;

export const ContainerContactData = styled.div`
    input, select {
        max-width: 100%;
    }
    .input-full > input {
        max-width: 100%;
    }
    .textfield-input{
        border: 1px solid #AAA9A9;
        color: '#AAA9A9';
        border-radius: 8px;
    }
    .regardContent{
        margin-top: 32px;
    }

    @media only screen and (max-width : 450px) { 
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        margin-top: 32px;
        .wrapperInputs{
            width: 312px !important;
            flex-wrap: wrap !important;
            margin-bottom: 0px !important;
            margin-top: 0px !important;
            height: 128px !important;
        }
        #idStep1ContainerInputs{
            width: 95%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
        }
    }

    @media only screen and (max-width: 336px) {
        .WrapperDropdownInput{
            width: 100% !important;
        }
        .addressInput{        
            width: 100% !important;
        }
    }

    @media only screen and (max-width : 376px) {  
        .inputStep1{
            margin-top: 16px;
        } 
        .dropdownInput{
            border: 1px solid #AAA9A9;
            width: 335px;
        }
        .addressInput{        
            width: 335px;
            padding-bottom: 0px !important;
            padding-top: 16px !important;
            margin-top: 16px !important;
        }
        .customselect{
            padding-top: 0px  !important;
            padding-bottom: 0px  !important;
            background-color: transparent !important;
            color: inherit !important;
            border-width: 0 !important;
            padding: 0 !important;
            cursor: pointer !important;
            width: 104px;
        }
        .regards{
            margin-top: 32px;            
            display: flex;
            justify-content: center;
        }

        .radioGroupStep1{
            margin-top: 24px !important;
        }

        .rgpersonalData{
            margin-bottom: 0px !important;
        }

        .asesorContent{
            margin-top: 40px;
        }

        #op1{
            margin-bottom: 24px;
        }

        .secondRadio{
            margin-left: 0px !important;
        }

        .checkboxGroup{
            margin-top: 0px !important;
            width: 335px  !important;
        }
        .addressForm{
            margin-top: 40px !important;
            margin-bottom: 40px;
        }
        .secondInput{
            margin-top: 30px !important;
        }
        .textfield-input {
            width: 335px !important;
        }
        
        .WrapperDropdownInput{
            display: grid;
            place-items: center;
            width: 335px;
            padding-top: 16px;
            margin-top: 16px;
        }


    }

    @media only screen and (min-width : 377px) and (max-width: 450px) {   
        .inputStep1{
            margin-top: 16px;
        } 
        .dropdownInput{
            border: 1px solid #AAA9A9;
            width: 100%;
        }
        .addressInput{        
            width: 100%;
            padding-bottom: 0px !important;
            padding-top: 16px !important;
            margin-top: 16px !important;
        }
        .customselect{
            padding-top: 0px  !important;
            padding-bottom: 0px  !important;
            background-color: transparent !important;
            color: inherit !important;
            border-width: 0 !important;
            padding: 0 !important;
            cursor: pointer !important;
            width: 104px;
        }
        .regards{
            margin-top: 32px;            
            display: flex;
            justify-content: center;
        }

        .radioGroupStep1{
            margin-top: 24px !important;
        }

        .asesorContent{
            margin-top: 40px;
        }

        #op1{
            margin-bottom: 24px;
        }

        .secondRadio{
            margin-left: 0px !important;
        }

        .checkboxGroup{
            margin-top: 0px !important;
            width: 100% !important;
        }
        .addressForm{
            margin-top: 40px !important;
            margin-bottom: 40px;
        }
        .secondInput{
            margin-top: 30px !important;
        }
        .textfield-input {
            width: 100% !important;
        }
        
        .WrapperDropdownInput{
            display: grid;
            place-items: center;
            width: 100%;
            padding-top: 16px;
            margin-top: 16px;
        }
    }

    

    @media only screen and (min-width : 451px) and (max-width: 700px) {
        width: 90vw;
        .inputGroupStep1{
            width: 90vw;
        }
        .regards{
            width: 100%;
            text-align: center;
            padding-top: 32px;
        }

        .radioGroupStep1{
            display: flex;
            margin-top: 24px;
            margin-bottom: 40px;
        }
    }

    @media only screen and (min-width : 700px) and (max-width: 1000px) {
        .WrapperDropdownInput{
            display: grid;
            place-items: center;
            padding-top: 16px;
            margin-top: 16px;
            width: 340px;
        } 
        .addressInput{        
            width: 340px;
            padding-bottom: 0px !important;
        }
        .customselect{
            padding-top: 0px  !important;
            padding-bottom: 0px  !important;
            background-color: transparent !important;
            color: inherit !important;
            border-width: 0 !important;
            padding: 0 !important;
            cursor: pointer !important;
            width: 104px;
        }
        .regards{
            margin-top: 32px;            
            display: flex;
            justify-content: center;
        }

        .inputGroupStep1{
            width: 704px;
            display: flex !important;           
            justify-content: space-between;  
            align-items: baseline;
        }

        .inputGroupFrameAddress{
            width: 704px;
            display: flex !important;           
            justify-content: space-between;  
            align-items: flex-end !important;
        }

        .inputGroupStep1_v2{
            display: flex !important;
            align-items: flex-end !important;
            justify-content: space-between;        
        } 

        .radioGroupStep1{
            display: flex;
            margin-top: 24px;
            margin-bottom: 40px;
        }
        .rgpersonalData{
            margin-bottom: 0px !important;
        }
        .secondRadio{
            margin-left: 0px !important;        }

        .checkboxGroup{
            margin-top: 40px !important;
            width: 704px  !important;
        }
        .addressForm{
            margin-top: 40px !important;
        }
        .secondInput{
            margin-top: 30px !important;
        }
        .textfield-input {
            width: 335px !important;
        }
        .inputStep1{
            width: 340px !important;
        }
        .textfield-input {
            width: 340px !important;
        }
        .regardContent{
            display: flex;
            flex-direction: column;
            --align-items: center;
            p {
                justify-content: flex-start;
            }
        }
        .asesorContent{
            margin-top: 40px;
            p {
                justify-content: flex-start;
            }
        }

    }

    @media only screen and (min-width : 1001px) and (max-width: 1727px) {
        #SelectInputDrop{
            display: flex;
            align-items: stretch;
        }     
        .WrapperDropdownInput{
            display: grid;
            place-items: center;
            padding-top: 16px;
            margin-top: 16px;
            width: 302px;
        }     
        .inputGroupStep1{
            display: flex;
            height: 80px;
            justify-content: space-between;
            align-items: baseline;
        }  
        .asesorContent{
            margin-top: 40px;
        }
        .inputGroupFrameAddress{
            display: flex;
            height: 80px;
            justify-content: space-between;
            align-items: baseline;
        }
        .row_1{
            margin-bottom: 16px !important;
        }
        .row_2{
            height: 66px !important;
        }
        .inputGroupStep1_v2{
            display: flex !important;
            align-items: flex-end !important;
            justify-content: space-between !important;        
        }  
        .radioGroupStep1{
            display:flex;
            margin-top: 8px !important;
            --height: 56px !important;
        } 
        .customselect{
            padding-top: 0px  !important;
            padding-bottom: 0px  !important;
            background-color: transparent !important;
            color: inherit !important;
            border-width: 0 !important;
            padding: 0 !important;
            cursor: pointer !important;
            width: 104px;
        }
        .inputStep1{
            width: 302px !important;
        }

        #idStep1ContainerInputs{
            width: 100%;
            margin-bottom: 53px;
        }
        .addressInput{        
            width: 302px;
            padding-bottom: 0px !important;
        }       
        .regards{
            margin-top: 32px;          
        }
        .addressForm{
            margin-top: 40px !important;
        }
        .checkboxGroup{
            margin-top: 40px !important;
        }

    }

    @media only screen and (min-width : 1728px) {
        .WrapperDropdownInput{
            display: grid;
            place-items: center;
            width: 392px;
            margin-top: 16px;
            padding-top: 16px;
            padding-right: 24px !important;
        }  
        .inputGroupStep1{
            display: flex;
            height: 80px;
            justify-content: space-between;
            align-items: baseline;
        }
        .inputGroupFrameAddress{
            display: flex;
            height: 80px;
            justify-content: space-between;
            align-items: baseline;  
        }
        .row_1{
            margin-bottom: 16px !important;
        }
        .row_2{
            height: 66px !important;
        }
        .inputGroupStep1_v2{
            display: flex !important;
            align-items: flex-end !important;
            justify-content: space-between;        
        }  
        #viaInput{
            margin-left: 32px;
        }
        #SelectInputDrop{
            display: flex;
            align-items: stretch;
        }     
        .customselect{
            padding-top: 0px  !important;
            padding-bottom: 0px  !important;
            background-color: transparent !important;
            color: inherit !important;
            border-width: 0 !important;
            padding: 0 !important;
            cursor: pointer !important;
            width: 104px;
        }
        .inputStep1{
            width: 392px !important;
            padding-right: 24px !important;
        }
        #idStep1ContainerInputs{
            width: 100%;
            margin-bottom: 53px;
        }
        .addressInput{        
            width: 392px;
            padding-bottom: 0px !important;
        }   
        .regards{
            margin-top: 64px;          
        }
        .regardContent{
            --margin-bottom: 32px;
        }
        .asesorContent{
            margin-top: 40px;
        }
        .addressForm{
            margin-top: 40px !important;
            margin-bottom: 40px !important;
        }
        .radioGroupStep1{
            display:flex;
            margin-top: 8px !important;
            --height: 56px !important;
        }  

    }
`;

export const SelectInline = styled(Select)`
    margin-top: 18px;
    &.little-select {
        max-width: 150px;
    }
    .rc-select-selector{
        border: 1px solid rgba(105,97,88,0.4);
    }
    .select-error-message {
        color: var(--redColorError);
        position: inherit;
        bottom: 0px;
        left: 8px;
        font-size: 14px;
        line-height: 16px;
    }
    @media only screen and (min-width : 1728px) {
        width: 392px;
    }
 
`;

const _buttonMobile = `
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    justify-content: normal;

    button:last-child {
        width: 100%;
        order: -1;
        height: 48px;
        margin-bottom: 1rem;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100% !important;
    ${media.sm`
        ${_buttonMobile}
    `};
    
    ${media.xs`
        ${_buttonMobile}
    `};

    .newUiBtn{
        justify-content: center;
        border-radius: var(--Radius-Radius-16, 16px);
        white-space: nowrap;
        text-align: center;
        letter-spacing: 0.02px;
        padding: 16px 20px;
        font: 400 18px / 133% Lexend, -apple-system, Roboto, Helvetica, sans-serif;
    }

    .disabledBtn{
          border-radius: var(--Radius-Radius-16, 16px);
          background-color: var(--Background-Disabled-Subtle, #e7e7e8);
          color: var(--Text-Disable-Disabled, #84878b);
    }
    .enabledBtn{
        background-color: var(--orangeColorMain);
        color: #FFFFFF;
    }

    @media only screen and ${mediaQuery.mobileS} {  
        margin-top: 40px  !important;
        margin-bottom: 10px !important;
        #identity-btn-env{
            width: 93vw !important;
        }
        .identity-btn-send{
            width: 76% !important;
        }
        .buttonStep1{
            width: 312px !important;
            margin-bottom: 0px !important;
        }
    }


    @media only screen and (min-width : 376px) and (max-width: 451px) {   
        margin-top: 44px  !important;
        margin-bottom: 10px !important;
        .buttonStep1{
            width: 312px !important;
        }
        #identity-btn-env{
            margin-bottom: 0px !important;
        }
        #identity-btn-env-12{
            width: 312px;
          }
    }

    @media only screen and (max-width: 451px) {   
        margin-top: 32px !important;
        margin-bottom: 33px !important;
        flex-direction: column !important;
        align-items: center;
        display:flex;
    }

    @media only screen and (min-width : 451px) and (max-width: 700px) {

        .newUiBtn{
            width: 200px;
        }
        
        .identity-btn-send{
            margin-top: 40px;
            margin-bottom: 40px;
        }

        #identity-btn-env{
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }

    @media only screen and (min-width : 701px) and (max-width: 1000px) {
        margin-top: 48px !important;
        margin-bottom: 152px !important;
        width: 704px !important;
        display: flex !important;
        flex-direction: row !important;
        --justify-content: flex-end !important;
        justify-content: center;
        .newUiBtn{
            width: 200px;
        }
    }

    @media only screen and (min-width : 1001px) and (max-width: 1727px) {
        #identity-btn-env{
            margin-top: 40px;
            margin-bottom: 40px;
            width:142px;
            height: 40px;
        }
        .identity-btn-send{
            margin-top: 40px;
            margin-bottom: 40px;
        }
        .newUiBtn{
            width: 200px;
        }
    }
    
    @media only screen and (min-width : 1728px) {
        margin-top: 48px !important;
        margin-bottom: 64px;
        .newUiBtn{
            width: 200px;
        }
    }

`;

export const WarningText = styled.div`
    display: inline-flex;
    .divTextWarning{
        display: flex;
        gap: 8px;
        color: var(--Text-Gray-Subtle, #8f9295);
        letter-spacing: 0.02px;
        flex-wrap: wrap;
        font: 400 14px/20px var(--Font-Family-Body, Calibri);
        padding-right: 8px;
    }
    @media only screen and (max-width: 450px) {
        margin-bottom: 56px;
        .divTextWarning{
            width: 100%;
            max-width: 312px;
            color: var(--Text-Gray-Subtle, #8f9295);
            letter-spacing: 0.02px;
            font: 400 14px/20px var(--Font-Family-Body, Calibri);
        }
        .sc-fTFjTM::after {
            left: 96%;
        }
        .styles__ContainerTooltipText-sc-1vevxat-0{
            width: 320px;
            margin-left: -308px;
        }
    }

    @media only screen and  (min-width: 451px) {
        margin-top: 56px;
        margin-bottom: 32px;
    }   

`;

export const DivHeader = styled.div`
    height: 20px;
    margin-bottom: 56px;

    #idDivBtn{
        width: 24px;
        height: 24px;
        background: url(${flecha});
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        overflow: hidden;
        opacity: 1;
        margin-right: 8px;
    }

    #idDivBtnText{
      width: 34px;
      color: rgba(255,79,0,1);

      font-family: Calibri,sans-serif;
      font-weight: Regular;
      font-size: 16px;
      opacity: 1;
      text-align: left;
    }

    @media only screen and ${mediaQuery.mobileS} {
        .backButtonMobile {
        width: 100% !important;
        }
    }

    /* Medidas responsivas */
    /* Small Devices, Phones */ 
    @media only screen and (max-width : 1000px) {
        margin-bottom: 0px;
        #idSlogan{
            display: none !important; 
        }
    }
`;

export const TextStyledStep0 = styled(Text)`
    color: #FF4F00;
    font-weight: 700;
    font-size: 16px;    
    line-height: 20px;
`;

export const TextRegards = styled(Text)`
    font-family: 'FS Emeric';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #696158;

    @media only screen and (max-width : 450px) {   
        text-align: center;
    }
    
`;

export const TextGray = styled(Text)`
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #696158;
    @media only screen and (max-width : 450px) {   
        justify-content: flex-start;
    }
    @media only screen and (min-width : 451px) and (max-width: 700px) {
        text-align: left;
    }
    @media only screen and (min-width : 700px) and (max-width: 1000px) {
        text-align: center;
        display: flex;
        justify-content: center;
    }
`;

export const CheckboxGroup = styled.div` 
    margin-top: 53px !important;
    margin-bottom: 32px !important;
    .ContentFrameAutho{
        display: flex;
        margin-bottom: 15px;
        align-items: center;
        .btNAPJ input[type="checkbox"]:checked + .box:after {
          box-shadow: 0px 0px 0px 2px rgba(0, 204, 204, 0.2);
          background-color: var(--Background-Secondary-Default, #04a6a2);
            border-color: #04A6A2;

        }

    }
    @media only screen and (max-width: 335px) {
        width: 76% !important;
        margin-bottom: 0px !important;
    }

    @media only screen and  (max-width: 450px) {  
        margin-bottom: 0px !important;
        margin-top: 0px !important;
        width: 335px !important;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        #check1{
            margin-bottom: 24px !important;
        }
        .ContentFrameAutho{
            width: 312px;
            display: flex;
            margin-bottom: 16px;
            align-items: center;
        }
    }
    @media only screen and (min-width : 451px) and (max-width: 700px) {  
        width: 90vw !important;
    }
    @media only screen and (min-width : 1001px) and (max-width: 1728px) {
        margin-top: 40px !important;
        .chkAcep{
            padding-bottom: 16px;
        }
    }
    .divCheck{
        background: #F9F9FB;
        border: 1px solid #696158;
        margin-right: 10px;
        border-radius: 4px;
        width: 20px;
        height: 20px;
        border-color: rgba(118, 118, 118, 0.3);
    }
    .textBold{
        cursor: pointer;
        color: #4e5258;
        font-variant-numeric: lining-nums proportional-nums;
        letter-spacing: 0.02px;
        text-decoration-line: underline;
        max-width: 409px;
        font: 400 16px/24px Lexend, -apple-system, Roboto, Helvetica, sans-serif;
    }
    .textBold2{
        cursor: pointer;
        color: #8f9295;
        font-variant-numeric: lining-nums proportional-nums;
        letter-spacing: 0.02px;
        text-decoration-line: underline;
        font: 400 16px/24px Lexend, -apple-system, Roboto, Helvetica, sans-serif;
    }

    .textCheckBox{
        color: #8f9295;
        font-variant-numeric: lining-nums proportional-nums;
        letter-spacing: 0.02px;
        font: 400 16px/24px Lexend, -apple-system, Roboto, Helvetica,sans-serif;
    }
`;