import React, { useState, useEffect, useRef } from "react";
import { MainContainer } from "../../components/MainContainer";
import { useConfigBiometric } from "../../hooks/useConfigBiometric";
import {
  useDispatchDigitalTransfersContext,
  useStateDigitalTransfersContext,
} from "../../context/DigitalTransfersContext";
import { HeaderStep } from "../../../App/pages/layout-sin-asesor/HeaderSteps";
import { Container } from "@prima-afp/prima-ui-elements/dist/layouts";
import biometria from "../../../shared/components/Loading/biometria.gif";
import {
  Step3Wrapper,
  BodyModalReco,
  ABButton,
} from "../../componentes-sin-asesor/StepsRecognition/styles";
import info from "../../componentes-sin-asesor/StepsRecognition/resources/info.svg";
import AlertImg from "../../assets/images/alertRed.svg";
import ResetImg from "../../assets/images/reset.svg";
import { Text } from "../../../shared/components/Text";
import { Button } from "@prima-afp/prima-ui-elements/dist/core";
import { useHistory } from "react-router-dom";
import { FPhi as FPhiSelphi } from "@prima-afp/selphi-widget-component";
import { ContainerCaptureSelfieDigitalTransfers } from "@prima-afp/prima-security/module/component";
import { BiometricWrapper } from "../Style";
import { useCatchFetch } from "../../hooks/useCatchFetch";
import { Abandon, CreateTransfer, helpMail, SavePhotoReco } from "../../services";
import { WhatsApp } from "../../components/BtnWhatsapp/index";
import { sendToData } from "../../constants/sendToDataLayer";
import { updateUrl } from "../../constants/updateUrl";
import { TraspasoModal } from "modules/shared/components/Modal";
import asistenteVirtual from "../../assets/images/asistenteVirtual.svg";
import imageLike from "../../assets/images/like.svg";
import query from "../../assets/images/Consulta-circulo.svg";
import SelfieRecomendations from "../../components/SelfieRecomendations/SelfieRecomendations";
import Toast from "../../components/Toast/Toast";

export const BiometricSelfiePage = () => {
  const config = useConfigBiometric();
  const dispatch = useDispatchDigitalTransfersContext();
  const {
    showLoading,
    imageBiometric,
    biometricVerified,
    userWeb,
    AttemptsQ,
    urlFlow,
    lastStep,
    messagePage,
    skipReco,
  } = useStateDigitalTransfersContext();
  const history = useHistory();
  const [showWidget, setShowWidget] = useState(false);
  const [textAttempts, setTextAttempts] = useState("Tienes 3(tres) intentos.");
  const [textAttemptsQ, setTextAttemptsQ] = useState("3 intentos");
  const enabledModal = useRef(false);
  const { SendFetch, SetLoading } = useCatchFetch();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 445);
  const [networkType, setNetworkType] = useState("");
  const [key, setKey] = useState(Date.now());
  const arrayFund = [{item_id: "F_1", item_name: "Fondo 1", fundType: 1},{item_id: "F_2", item_name: "Fondo 2", fundType: 2},{item_id: "F_3", item_name: "Fondo 3", fundType: 3},{item_id: "F_0", item_name: "Fondo 0", fundType: 0}];


  useEffect(() => {
    setKey(Date.now());
  }, []);

  let originalTitle = document.title;
  let attentionTitle = ["¡Un momento!", "Aún no formas parte de la mejor AFP."];
  let attentionIndex = 0;
  let isFlashing = false;

  function startFlashing() {
    if (!isFlashing) return;
    document.title = attentionTitle[attentionIndex];
    attentionIndex = (attentionIndex + 1) % attentionTitle.length;
    setTimeout(startFlashing, 1000);
  }

  useEffect(() => {
    if (typeof document !== "undefined") {
      const handleVisibilityChange = () => {
        if (document.hidden) {
          isFlashing = true;
          startFlashing();
        } else {
          isFlashing = false;
          document.title = originalTitle;
        }
      };

      document.addEventListener("visibilitychange", handleVisibilityChange);

      // Limpia el evento cuando el componente se desmonte
      return () => {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      };
    }
  }, []);

  useEffect(() => {
    if (skipReco == false) {
      setShowWidget(true);
    } else {
      setShowWidget(false);
    }
  }, [skipReco]);

  // useEffect(() => {
  //   // Crear un nuevo elemento script
  //   const script = document.createElement("script");
  //   script.type = "text/javascript";
  //   script.src = `${process.env.REACT_APP_DYNATRACE_URL}`;
  //   script.crossOrigin = "anonymous";
    
  //   // Agregar el script al head del documento en la posición más alta posible
  //   document.head.insertBefore(script, document.head.firstChild);

  //   // Limpiar el script cuando el componente se desmonte
  //   return () => {
  //     document.head.removeChild(script);
  //   };
  // }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 445);
  };


  useEffect(() => {
    const prevLink = document.querySelector(
      'link[rel="prev"][href="https://traspaso.prima.com.pe/pasos2"]'
    );
    const nextLink = document.querySelector(
      'link[rel="next"][href="https://traspaso.prima.com.pe/pasos3validacion-biometrica"]'
    );

    // Si los elementos existen, elimínalos
    if (prevLink) {
      document.head.removeChild(prevLink);
    }
    if (nextLink) {
      document.head.removeChild(nextLink);
    }

    const linksAdd = [
      { rel: "prev", href: "https://traspaso.prima.com.pe/pasos3" },
      { rel: "next", href: "https://traspaso.prima.com.pe/pasos4" },
    ];

    linksAdd.forEach((link) => {
      if (!document.querySelector(`head link[href="${link.href}"]`)) {
        const newLink = document.createElement("link");
        newLink.rel = link.rel;
        newLink.href = link.href;
        document.head.appendChild(newLink);
      }
    });
  });

  useEffect(() => {
    if (imageBiometric && biometricVerified && lastStep != 4) {
      dispatch({
        type: "COMPLETE_BIOMETRIC_STEP",
      });
      dispatch({
        type: "FIELD",
        payload: { field: "showDivBenefits", value: true },
      });
      dispatch({ type: "FIELD", payload: { field: "stepCurrent", value: 5 } });
      const currentUrl = window.location.href;
      let payload = {
        section: "paso 3",
        option: "continuar",
        count_3: 1,
        dni: userWeb.documentNumber,
        telefono: userWeb.phoneNumber,
        email: userWeb.email,
        biometria: userWeb.biometricStatus,
      };
      sendToData(currentUrl, "step", "paso_3", payload, urlFlow);
      if (lastStep < 4) {
        dispatch({ type: "FIELD", payload: { field: "lastStep", value: 4 } });
      }
      
      createDT();            
    }
  }, [imageBiometric, biometricVerified]);


  const createDT= () => {

    SetLoading(true);
    const bodyToCreate = {documentType: userWeb.documentType, documentNumber: userWeb.documentNumber, byAssessor: false};
    SendFetch(CreateTransfer(bodyToCreate))
        .then((res) => {
            if (res.code === 1 || res.code === 2) {
                if(res.code === 2){
                    dispatch({ type: 'FIELD', payload: { field: 'finishCircuit', value: true } });
                }
                SetLoading(false);              
                dataLayer.push({
                    RegisteredDocUser: userWeb.documentNumber,
                });   
                dispatch({ type: 'FIELD', payload: { field: 'showDivBenefits', value: false } });
                dispatch({
                    type: 'GO_TO_FINISH_PAGE',
                });

                const currentUrl = window.location.href;
                const timestamp = Date.now();
                const fund = arrayFund.find(item => item.fundType === userWeb.fundType);
                var payload = {
                    section: "paso 3", option: "finalizar solicitud", 
                    count_4: 1, 
                    dni: userWeb.documentNumber,
                    telefono: userWeb.phoneNumber,
                    email: userWeb.email,
                    edad: userWeb.age,
                    transaction_id : "T_" + timestamp,
                    items: [                        
                        { item_id: fund.item_id, item_name: fund.item_name}                       
                    ]
                };                 
                dispatch({ type: "FIELD", payload: { field: "stepCurrent", value: 5 } });
                sendToData(currentUrl, "step", "paso_4", payload, urlFlow);                                      
                dispatch({ type: 'FIELD', payload: { field: 'enabledToBack', value: false } });
                updateUrl(history, currentUrl,'/finalizacion-solicitud');           
            }else {
                SetLoading(false);
                setTimer(0);
                setCode('');
                dispatch({
                    type: 'SET_CONFIG_MODAL',
                    payload: {
                        idModal: 'btn-step4-error-createDT',
                        content: res.message,
                    }
                });
            }
        });

};

  useEffect(() => {
    // Agregamos el event listener cuando el componente se monta
    window.addEventListener("resize", handleResize);

    // Limpiamos el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const currentUrl = window.location.href;
    if (
      userWeb.documentType != "00" ||
      userWeb.documentNumber == null ||
      userWeb.documentNumber == ""
    ) {
      updateUrl(history, currentUrl, "/pasos0");
    }
  }, []);

  useEffect(() => {
    if (AttemptsQ == 2) {
      setTextAttempts("Tienes 2(dos) intentos.");
      setTextAttemptsQ("dos intentos");
    }
    if (AttemptsQ == 1) {
      setTextAttempts("Tienes 1(un) intento.");
      setTextAttemptsQ("un intento");
    }
  }, [AttemptsQ]);

  const reset = () => {
    dispatch({
      type: "FIELD",
      payload: { field: "biometricVerified", value: false },
    });
    dispatch({
      type: "FIELD",
      payload: { field: "imageBiometric", value: null },
    });
    const currentUrl = window.location.href;
    let payload = {
      section: "paso 3",
      message: messagePage,
      option: "reintentar",
    };
    payload.dni = userWeb.documentNumber;
    sendToData(currentUrl, "button_click", "button_click", payload);
  };

  const backReco = async (type, errorObject) => {
    let error = errorObject?.message.length > 0;
    if (error) {
      let option =
        "erro_type: " +
        errorObject.type +
        "/error_message:" +
        errorObject.message;
      console.log("error option", option);
      let payload = {
        section: "paso 3",
        documentNumber: userWeb.documentNumber,
        message: "Error devuelto por widget",
        option: `${errorObject.type} - ${errorObject.message}`,
      };
      sendToData(window.location.href, "button_click", "error_widget", payload);
    }
    enabledModal.current = true;
    if (type == "clickBtnNoCamera") {
      var payload = {
        section: "paso 3",
        message: "necesitamos acceder a tu camara",
        option: "entendido",
        dni: userWeb.documentNumber,
      };
      sendToData(window.location.href, "button_click", "button_click", payload);

      dispatch({
        type: "BACK_DOCUMENTATION_STEP",
      });
      dispatch({
        type: "FIELD",
        payload: { field: "showDivBenefits", value: true },
      });
      dispatch({
        type: "FIELD",
        payload: { field: "biometricVerified", value: false },
      });
      dispatch({
        type: "FIELD",
        payload: { field: "imageBiometric", value: null },
      });
      dispatch({
        type: "FIELD_SECOND",
        payload: { field1: "userWeb", field2: "biometric", value: null },
      });
      window.location.reload(true);
    } else if (type == "ShowModalNoCamera") {
      var body = {
        documentType: userWeb.documentType,
        documentNumber: userWeb.documentNumber,
        status: "camara_bloqueada",
      };
      SendFetch(SavePhotoReco(body)).then((res) => {});
      var payload2 = {
        section: "paso 3",
        message: "necesitamos acceder a tu camara",
        option: "entendido",
        dni: userWeb.documentNumber,
      };
      sendToData(window.location.href, "mensaje_click", "mensaje", payload2);
    }
  };

  useEffect(() => {
    // Agregar un event listener cuando el componente se monta
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Eliminar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [imageBiometric, biometricVerified]);

  const handleBeforeUnload = async (e) => {
    if (enabledModal.current == false) {
      const message = "¿Seguro que quieres salir?";
      e.returnValue = message;
      window.confirm(message);
      try {
        let currentStep = 0;
        if (imageBiometric == null && biometricVerified == false) {
          currentStep = 4;
        }
        if (
          imageBiometric != null &&
          (biometricVerified == false || biometricVerified == true)
        ) {
          currentStep = 5;
        }
        let BodyPayload = {
          step: currentStep,
          documentNumber: userWeb.documentNumber,
          documentType: "00",
          active: true,
        };
        await SendFetch(Abandon(BodyPayload));
      } catch (error) {
        console.error("error:", error);
      }
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [helpCall, setHelpCall] = useState(false);

  const openModal = () => {
    const currentUrl = window.location.href;
    let payload = {
      section: "paso 3",
      option: "problemas para continuar",
    };
    sendToData(currentUrl, "button_click", "button_click", payload);
    setModalOpen(true);
  };

  const closeModal = () => {
    setHelpCall(false);
    setModalOpen(false);
  };

  const sendEmail = async () => {
    const currentUrl = window.location.href;
    let body = {
      indicator: 2,
      parameters: {
        documentType: "00",
        documentNumber: userWeb.documentNumber,
      },
    };
    await SendFetch(helpMail(body));

    let payload = {
      section: "paso 3",
      message: "contacta con un asesor",
      option: "solicitar llamada",
      dni: userWeb.documentNumber,
    };
    sendToData(currentUrl, "button_click", "button_click", payload);
    setHelpCall(true);
  };

  const getValue = (str, currentURL) => {
    const startIndex = currentURL.indexOf(str);
    if (startIndex !== -1) {
      // Encontrar la posición de "&" después de "utm_source="
      const endIndex = currentURL.indexOf("&", startIndex);
      // Extraer la subcadena entre "utm_source=" y "&"
      const strValue =
        endIndex !== -1
          ? currentURL.substring(startIndex + str.length, endIndex)
          : currentURL.substring(startIndex + str.length);
      return strValue.substring(1);
    } else {
      return "not set";
    }
  };

  const showComponent = () => {
    const currentUrl = window.location.href;
    const utm_source = getValue("utm_source", currentUrl);
    const utm_medium = getValue("utm_medium", currentUrl);
    const utm_campaign = getValue("utm_campaign", currentUrl);
    var payload = {
      section: "paso 3",
      option: "tomar foto",
      dni: userWeb.documentNumber,
      utm_source: utm_source,
      utm_medium: utm_medium,
      utm_campaign: utm_campaign,
    };
    sendToData(currentUrl, "button_click", "button_click", payload);
    setShowWidget(true);
  };

  const returnPrevious = () => {
    const currentUrl = window.location.href;
    dispatch({
      type: "BACK_DOCUMENTATION_STEP",
    });
    dispatch({
      type: "FIELD",
      payload: { field: "biometricVerified", value: false },
    });
    dispatch({
      type: "FIELD",
      payload: { field: "imageBiometric", value: null },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: { field1: "userWeb", field2: "biometric", value: null },
    });
    window.location.href.includes("asesor")
      ? updateUrl(history, currentUrl, "/asesor/pasos3")
      : updateUrl(history, currentUrl, "/pasos3");
  };

  return (
    <>
      {!isMobile && (
        <Container id="idContainerHeader">
          <HeaderStep />
        </Container>
      )}
      <MainContainer id="mainid">
        {imageBiometric === null && !biometricVerified && showWidget && (
          <BiometricWrapper id="wrapperid" key={key}>
            <p className="titleStep3">Espera a que el círculo verde cierre</p>
            <div className="wrapperWidgetSelfie">
              <ContainerCaptureSelfieDigitalTransfers
                config={config}
                loading={showLoading}
                biometric={{ FPhiSelphi }}
                backNoCamera={backReco}
              />
              <div className="helpSection">
                <a className="helpButton" onClick={openModal}>
                  <div className="iconHelp">
                    {" "}
                    <img
                      src={query}
                      style={{ width: "24px", height: "24px" }}
                      alt="queryIcon"
                    />{" "}
                  </div>
                  <span className="alertHelpText">
                    {" "}
                    ¿Problemas para continuar?
                  </span>
                </a>
              </div>
            </div>
            <div className="alertStep3">
              <div className="icon">
                {" "}
                <img
                  src={info}
                  style={{ width: "24px", height: "24px" }}
                  alt="infoIcon"
                />{" "}
              </div>
              <span className="alertText"> {textAttempts}</span>
            </div>
          </BiometricWrapper>
        )}

        <BiometricWrapper>
          {!helpCall && (
            <TraspasoModal isOpen={modalOpen} onClose={closeModal}>
              <div className="modal-content-inner">
                <img
                  src={asistenteVirtual}
                  alt="modal_image"
                  className="modal-image"
                />
                <h2 className="modal-title">Descuida, te ayudamos</h2>
                <p className="modal-description">
                  Solicita una llamada de nuestra asesora que te ayudará a
                  terminar tu traspaso.
                </p>
                <button onClick={sendEmail} className="modal-button">
                  Solicitar llamada
                </button>
              </div>
            </TraspasoModal>
          )}
          {helpCall && (
            <TraspasoModal isOpen={modalOpen} btnClose={false}>
              <div className="modal-content-inner">
                <img
                  src={imageLike}
                  alt="modal_image"
                  className="modal-image"
                />
                <h2 className="modal-title">Listo</h2>
                <p className="modal-description">
                  Nuestra asesora se comunicará contigo en unos momentos.
                </p>
                <button onClick={returnPrevious} className="modal-button">
                  Entendido
                </button>
              </div>
            </TraspasoModal>
          )}
        </BiometricWrapper>

        {imageBiometric &&
          !biometricVerified &&
          !showLoading &&
          userWeb.testABOption == 4 && (
            <Step3Wrapper>
              <div className="wrapperAlert">
                <img src={AlertImg} alt="alert" className="imgAlert" />

                <Text color="#696158" weight="600" className="msgAlert">
                  {messagePage}
                </Text>

                {AttemptsQ != null && (
                  <Text
                    color="#696158"
                    weight="400"
                    size="16"
                    style={{ marginBottom: "24px" }}
                  >
                    <b>Tienes {textAttemptsQ} más</b> para validar tu identidad.
                  </Text>
                )}
                <Button
                  id="btn-biometric-retry"
                  className="buttonReset"
                  onClick={reset}
                >
                  <div style={{ display: "flex" }}>
                    <img src={ResetImg} alt="reset" />
                    <Text color="#fff" style={{ marginLeft: "8px" }}>
                      Reintentar
                    </Text>
                  </div>
                </Button>
              </div>
            </Step3Wrapper>
          )}

        {imageBiometric &&
          !biometricVerified &&
          !showLoading &&
          userWeb.testABOption != 4 &&
          AttemptsQ > 0 && (
            <Step3Wrapper>
              <Toast
                message="No se ha podido confirmar tu identidad"
                duration={5000}
                colorBackground={"#FFE6E6"}
                colorBar={"#EE0000"}
                colorText={"292B2E"}
                type={"redAdlert"}
              />

              <SelfieRecomendations type="testAB" />

              <div style={{ display: "flex" }}>
                <ABButton id="btn-biometric-retry" onClick={reset}>
                  Validar identidad
                </ABButton>
              </div>

              <div className="helpSection">
                <div className="linkHelp" onClick={openModal}>
                  ¿No puedes validarte ahora?
                </div>
              </div>
            </Step3Wrapper>
          )}

        {imageBiometric &&
          !biometricVerified &&
          !showLoading &&
          userWeb.testABOption != 4 &&
          (AttemptsQ == 0 || AttemptsQ == null) && (
            <Step3Wrapper>
              <div className="wrapperAlert">
                <img src={AlertImg} alt="alert" className="imgAlert" />

                <Text color="#696158" weight="600" className="msgAlert">
                  {messagePage}
                </Text>

                {AttemptsQ != null && AttemptsQ > 0 && (
                  <Text
                    color="#696158"
                    weight="400"
                    size="16"
                    style={{ marginBottom: "24px" }}
                  >
                    <b>Tienes {textAttemptsQ} más</b> para validar tu identidad.
                  </Text>
                )}
                <Button
                  id="btn-biometric-retry"
                  className="buttonReset"
                  onClick={reset}
                >
                  <div style={{ display: "flex" }}>
                    <img src={ResetImg} alt="reset" />
                    <Text color="#fff" style={{ marginLeft: "8px" }}>
                      Reintentar
                    </Text>
                  </div>
                </Button>
              </div>
            </Step3Wrapper>
          )}

        {skipReco && !showWidget && !imageBiometric && !biometricVerified && (
          <TraspasoModal isOpen={true} btnClose={false}>
            <BodyModalReco className="modal-content-inner-skipReco">
              <img
                src={biometria}
                alt="modal_image"
                className="modal-image-skipReco"
              />
              <h2 className="title">Valida tu identidad con una selfie</h2>
              <p className="content">
                ¡Prepárate! Encuentra un lugar bien iluminado y asegúrate de que
                tu rostro esté centrado, sin cubrir y sin lentes. Relájate,
                mantente quieto y mira a la cámara. ¡Estamos aquí para ayudarte!
              </p>
              <Button onClick={showComponent} className="newUiBtn">
                Validar identidad
              </Button>
            </BodyModalReco>
          </TraspasoModal>
        )}
      </MainContainer>
      <WhatsApp />
    </>
  );
};
