import React from "react";
import { OptionsMenu } from "../../constants/data";
import LogoPrima from "../../../App/assets/images/logoPrimaOrange.svg";
import { HeaderCont } from "./Style";
import { redirectURL } from "../../constants/functions";
import { sendToDataLanding } from "../../../digital-transfers/constants/sendToDataLayer";
import { useStateDigitalTransfersContext } from "modules/digital-transfers/context/DigitalTransfersContext";
import { Button } from "@prima-afp/prima-ui-design-system/dist/ui-components";
import { useHistory } from "react-router-dom";

const HeaderLanding = ({ sectionsRef }) => {
  const { urlFlow } = useStateDigitalTransfersContext();
  const history = useHistory();
  function scrollToSection(option) {
    let sectionId = "";
    let optionDataLayer = "";
    switch (option) {
      case "Cámbiate hoy":
        sectionId = "page1";
        optionDataLayer = "cambiate hoy";
        break;
      case "¿Cuánto toma?":
        sectionId = "page2";
        optionDataLayer = "cuanto toma";
        break;
      case "Preguntas frecuentes":
        sectionId = "page4";
        optionDataLayer = "preguntas frecuentes";
        break;
      default:
        break;
    }
    const section = sectionsRef.current[sectionId];
    if (section) {
      const objectDataLayer = {
        event: "menu_click",
        payload: {
          section: "landing traspaso",
          sub_section: "menu",
          option: optionDataLayer,
        },
      };
      sendToDataLanding(objectDataLayer);
      section.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }

  const handleImageClick = () => {
    const objectDataLayer = {
      event: "button_click",
      payload: {
        section: "landing traspaso",
        sub_section: "logo_prima",
        option: "click_home",
      },
    };
    sendToDataLanding(objectDataLayer);
    window.location.href = "https://www.prima.com.pe/public-zone";
  };

  return (
    <HeaderCont>
      <div className={`headerLanding`}>
        <div className="contentHeader">
          <img
            src={LogoPrima}
            alt="PRIMA"
            onClick={handleImageClick}
            style={{ cursor: "pointer" }}
          />
          <div className="btnsHeader">
            {OptionsMenu.map((itemMenu, index) => {
              return (
                <a
                  key={index}
                  onClick={() => scrollToSection(itemMenu.description)}
                >
                  {itemMenu.description}
                </a>
              );
            })}
          </div>

          <Button
            id="btn-change2"
            className="btnHeader"
            label="Cámbiate ya"
            onClick={() => {
              redirectURL(history, "traspaso", {
                event: "menu_click",
                payload: {
                  section: "landing traspaso",
                  sub_section: "boton - menu",
                  option: "cambiate ya",
                },
              });
            }}
            size="large"
          />
        </div>
      </div>
      <div className="helpHeader"></div>
    </HeaderCont>
  );
};

export default HeaderLanding;
