import React from "react";
import ReactDOM from "react-dom";
// Redux

// Main Page
import PublicTransactionsApp from "./PublicTransactionsApp.jsx";

// Polyfill
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./polyfills";

// SW
import * as serviceWorker from "./serviceWorker";
import { StyleCoreProvider } from "@prima-afp/prima-ui-design-system/dist/providers";

ReactDOM.render(
  <React.StrictMode>
    <StyleCoreProvider assetsUrl={process.env.REACT_APP_ASSETS_URL}>
      <PublicTransactionsApp />
    </StyleCoreProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
